(function () {
    if ('modules' in $ && 'formCaptcha' in $.modules) {
        return;
    }

    var FormCaptcha = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.formCaptcha');

        self.start = function() {
            var key = '';

            if (self.container.length) {

                key = self.container.attr('data-key');

                grecaptcha.render('formCaptcha', {
                    'sitekey' : key
                });

                self.window.trigger('resize');

            }
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.start();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    var captcha = new FormCaptcha();

    window.formCaptchaCallback = captcha.init;
})();
