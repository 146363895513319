(function () {
    if ('modules' in $ && 'formCheckbox' in $.modules) {
        return;
    }

    var FormCheckbox = function () {
        var self = this;

        self.container = $('body');

        self.setupListner = function() {
            self.container.on('click', '.formCheckbox.disabled', function(event) {
                event.preventDefault();
            });
        };

        self.init = function() {
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.formCheckbox = new FormCheckbox();
})();
