(function () {
    if ('modules' in $ && 'seoText' in $.modules) {
        return;
    }

    var SeoText = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.seoText');
        self.link = $('.seoText__link');

        self.setupListner = function() {
            self.link.html(self.link.attr('data-text-default'));
            self.link.on('click', self.toggle);
        };

        self.toggle = function(event) {
            event.preventDefault();

            self.container.toggleClass('active');

            if (self.container.hasClass('active')) {
                self.link.html(self.link.attr('data-text-open'));
            } else {
                self.link.html(self.link.attr('data-text-default'));
            }
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.seoText = new SeoText();
})();
