(function () {
    if ('modules' in $ && 'rotator' in $.modules) {
        return;
    }

    var Rotator = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.rotator');
        self.list = $('.rotator__list');
        self.next = '';
        self.prev = '';

        self.setupListner = function() {
        };

        self.initSlider = function() {
            self.container.each(function() {
                var target = $(this),
                    slider = target.find('.rotator__list'),
                    prev = target.find('.rotator__prev'),
                    next = target.find('.rotator__next');

                slider.lightSlider({
                   item: 1,
                   slideMove: 1,
                   slideMargin: 1,
                   controls: false,
                   pager: false,
                   adaptiveHeight: true,
                   onSliderLoad: function () {
                       self.sliderArrows();
                   },
                   onAfterSlide: function () {
                       self.sliderArrows();
                   }
                });

                prev.click(function(){
                    slider.goToPrevSlide();
                });

                next.click(function(){
                    slider.goToNextSlide();
                });
            });
        };

        self.sliderArrows = function () {
           var items = self.container.find('.lSPager li'),
               total = items.length,
               active = self.container.find('.lSPager li.active').index() + 1;
           if (active === 1) {

               self.container.removeClass('last');
               self.container.addClass('first');

           } else if (active === total) {

               self.container.removeClass('first');
               self.container.addClass('last');

           } else {

               if (self.container.hasClass('first') || self.container.hasClass('last')) {

                   self.container.removeClass('first last');

               }

           }
       };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.initSlider();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.rotator = new Rotator();
})();
