(function () {
    if ('modules' in $ && 'graphics' in $.modules) {
        return;
    }

    var Graphics = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.decode = '';
        self.graphics = $('.graphics');

        /**
         * Setup event listener to corresponding formSlider block
         * @param {Object} container
         * @param {Object} target
         * @param {Number} max
         */
        self.setupListner = function(container, target, max) {
            target.on('state', function(event) {
                var value = event.value;

                self.update(container, value, max);
            });
        };

        /**
         * Waiting for corresponding formSlider block initialization
         * @param {Object} options
         */
        self.pending = function(options) {
            if (!options.target.hasClass('js_inited')) {

                options.target.on('inited', function() {
                    self.setup(options);
                });

            } else {

                self.setup(options);

            }
        };

        /**
         * Get corresponding formSlider block
         */
        self.start = function() {
            self.graphics.each(function() {
                var container = $(this),
                    selector = container.attr('data-container'),
                    target = '',
                    options = {
                        container: container,
                        target: ''
                    };

                if (selector) {

                    options.target = $(selector).find('.formSlider');

                } else {

                    options.target = container.closest('.formSlider');

                }

                self.pending(options);
            });
        };

        /**
         * Count percent values and updating block state
         * @param {Object} options
         */
        self.setup = function(options) {
            var max = self.decode(options.target.attr('data-max')),
                value = parseInt(self.decode(options.target.find('.formSlider__input').val()));

            self.update(options.container, value, max);
            self.setupListner(options.container, options.target, max);
        };

        /**
         * Update block state
         * @param {Object} container
         * @param {Number} value
         * @param {Number} max
         */
        self.update = function(container, value, max) {
            var value = parseInt(value / max * 100),
                state = '';

            if (value <= 20) {

                state = 'graphics__1';

            } else if (value > 20 && value <= 40) {

                state = 'graphics__2';

            } else if (value > 40 && value <= 60) {

                state = 'graphics__3';

            } else if (value > 60 && value <= 80) {

                state = 'graphics__4';

            } else if (value > 80) {

                state = 'graphics__5';

            }

            container.removeClass('graphics__1 graphics__2 graphics__3 graphics__4 graphics__5').addClass(state);
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
            self.decode = $.modules.formSlider.decode;
        };

        self.init = function() {
            self.importDefaults();
            self.start();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.graphics = new Graphics();
})();
