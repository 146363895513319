(function () {
    if ('modules' in $ && 'search' in $.modules) {
        return;
    }

    var Search = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.search');
        self.block = $('.search__formBox');
        self.form = $('.search__form');
        self.input = $('.search__input');

        self.setupListner = function() {
            self.body.on('click', '.search__link', self.blockShow);
            self.body.on('click', '.search__close', self.blockHide);
        };

        self.blockShow = function() {

            var target = $(this),
                block = target.siblings('.search__formBox');

            console.log(block);

            event.preventDefault();

            if (target.hasClass('active')) {
              target.removeClass('active');
            } else {
              target.addClass('active');
            }

            block.toggle();
            self.input.focus();

        };

        self.blockHide = function() {

            var target = $(this),
                block = target.closest('.search__formBox');

            event.preventDefault();

            block.toggle();
            self.form[0].reset();

        };



        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.search = new Search();
})();
