(function () {
    if ('modules' in $ && 'formRadio' in $.modules) {
        return;
    }

    var FormRadio = function () {
        var self = this;

        self.body = '';

        self.setupListner = function() {
            self.body.on('click', '.formRadio .disabled', function(event) {
                event.preventDefault();
            });
            self.body.on('mouseenter', '.formRadio .tooltip', self.tooltip);
        };

        self.tooltip = function(event) {
            event.preventDefault();

            var target = $(this),
                eventType = '';

            if (!target.hasClass('tooltip_js_inited')) {

                eventType = (Modernizr.touchevents) ? 'click' : 'hover';

                target.tooltipster({
                    delay: 100,
                    animation: 'fade',
                    trigger: eventType
                })
                .addClass('tooltip_js_inited');

                target.tooltipster('open');

            }

        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.formRadio = new FormRadio();
})();
