(function () {
    if ('modules' in $ && 'tabs' in $.modules) {
        return;
    }

    var Tabs = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.tabs');
        self.list = $('.tabs__contentList');
        self.listMobile = $('.tabs__contentListMobile');
        self.item = $('.tabs__item');
        self.link = $('.tabs__link');

        self.setupListner = function() {

        };

        self.initSlider = function() {
            var slider = self.list.lightSlider({
                    item: 1,
                    slideMove: 1,
                    slideMargin: 0,
                    controls: false,
                    pager: false,
                    mode: 'fade',
                    adaptiveHeight: true
                }),
                sliderMobile = self.listMobile.lightSlider({
                    item: 1,
                    slideMove: 1,
                    slideMargin: 0,
                    controls: false,
                    pager: false,
                    mode: 'fade',
                    adaptiveHeight: true,
                    enableTouch: false,
                    enableDrag: false,
                    freeMove: false
                })

            self.link.on('click', function() {
                var target = $(this),
                    parent = target.parent(),
                    index = parent.index();

                event.preventDefault();
                slider.goToSlide(index);
                sliderMobile.goToSlide(index);

                self.item.removeClass('active');
                parent.addClass('active')
            });
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.initSlider();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.tabs = new Tabs();
})();
