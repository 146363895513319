(function () {
    if ('modules' in $ && 'footer' in $.modules) {
        return;
    }

    var Footer = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.footer');
        self.navigation = $('.footer__navigation');
        self.navigationList = $('.footer__navigation ul');
        self.items = self.navigationList.find('li');
        self.link = $('.footer__navigation__link');
        self.info = $('.footer__info');
        self.social = $('.footer__social');
        self.resizeTimer = '';

        self.setupListner = function() {
            self.link.on('click', self.toggle);

            self.window.on('resize', self.resize);
        };

        self.navigationInit = function() {
            self.navigation.addClass('js_inited');
        };

        self.toggle = function(event) {
            if (event) {

                event.preventDefault();
                self.link.toggleClass('active');

            }

            var count = parseInt(self.navigationList.attr('data-hidden-count')) * (-1);

            self.items.slice(count).toggleClass('hidden');

            self.window.trigger('resize');
        };

        self.setup = function() {
            self.container.addClass('js_sticky');
        };

        self.stickBottom = function() {
            self.container.css({
                'display': 'block'
            });

            self.wrapperHeight = self.container.outerHeight();

            self.body.css('padding-bottom', self.wrapperHeight);
        };

        self.resize = function(event) {
            self.stickBottom(); // removed delay because footer has expand elements
        };

        self.copy = function() {
            self.info.clone().addClass('mobile').insertAfter(self.social);
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.copy();
            self.setup();
            self.toggle();
            self.navigationInit();
            self.stickBottom();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.footer = new Footer();
})();
