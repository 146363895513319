(function () {
    if ('modules' in $ && 'headerPrivate' in $.modules) {
        return;
    }

    var HeaderPrivate = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.headerPrivate');
        self.link = $('.headerPrivate__link');
        self.block = $('.headerPrivate__box');

        self.setupListner = function() {
        };

        self.blockShowHide = function() {
            self.link.on('click', function() {
                var target = $(this);

                event.preventDefault();

                if (target.hasClass('active')) {
                  target.removeClass('active');
                } else {
                  target.addClass('active');
                }

                self.block.toggle();
            });
        };

        self.blockHide = function() {
            self.body.on('click', function(event) {
                var target = event.target;

                if ( target.closest('.headerPrivate__box') == null && target.closest('.headerPrivate__link') == null) {
                    self.block.hide();
                }
            });
        };



        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.blockShowHide();
            self.blockHide();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.headerPrivate = new HeaderPrivate();
})();
