// (function () {
//     if ('modules' in $ && 'navigationDesktop' in $.modules) {
//         return;
//     }

//     var NavigationDesktop = function () {
//         var self = this;

//         self.window = '';
//         self.body = '';
//         self.menuItem = $('.navigationDesktop__item');
//         self.dropDown = '';

//         self.setupListner = function() {
//             self.menuItem.hoverIntent( self.enable, self.disable, 200 );
//         };

//         self.enable = function() {
//             self.dropDown = $('ul', this);

//             if (self.dropDown.length) {
//                 var containerWidth = $(this).closest('.navigationDesktop').width(),
//                     position = $(this).position().left,
//                     dropDownWidth = self.dropDown.width();

//                 if ((position + dropDownWidth) > containerWidth) {
//                     self.dropDown.css({
//                         'left': 'auto',
//                         'right': '0'
//                     });
//                 } else {
//                     self.dropDown.css({
//                         'left': '0',
//                         'right': 'auto'
//                     });
//                 }
//             }

//             self.dropDown.slideDown(250);
//         }

//         self.disable = function() {
//             if (self.dropDown.length) {
//                 self.dropDown.slideUp(250);
//                 self.dropDown = '';
//             }
//         }

//         self.importDefaults = function() {
//             self.body = $.modules.main.body;
//             self.window = $.modules.main.window;
//         };

//         self.init = function() {
//             self.importDefaults();
//             self.setupListner();
//         };
//     };

//     if (!('modules' in $)) {
//         $.modules = {};
//     }

//     $.modules.navigationDesktop = new NavigationDesktop();
// })();
