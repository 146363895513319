(function () {
    if ('modules' in $ && 'formStars' in $.modules) {
        return;
    }

    var FormStars = function () {
        var self = this;

        self.body = '';
        self.container = $('.formStars');
        self.item = $('.formStars .stars__item');

        self.setupListner = function() {
            self.item.on('mouseover', self.over);
            self.item.on('mouseleave', self.leave);
            self.item.on('click', self.click);
        };

        self.over = function() {
            var target = $(this),
                container = target.closest('.stars'),
                index = target.index() + 1;

            container.addClass('stars__hover__' + index);
        };

        self.leave = function() {
            var target = $(this),
                container = target.closest('.stars'),
                index = target.index() + 1;

            container.removeClass('stars__hover__' + index);
        };

        self.click = function(event) {
            event.preventDefault();

            var target = $(this),
                container = target.closest('.stars'),
                input = container.siblings('.formStars__input'),
                index = target.index() + 1;

            if (container.hasClass('stars__' + index)) {

                container.removeClass('stars__' + index);
                input.val(0);

            } else {

                container.removeClass('stars__1 stars__2 stars__3 stars__4 stars__5');
                container.addClass('stars__' + index);
                input.val(index);

            }


        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.formStars = new FormStars();
})();
