(function () {
    if ('modules' in $ && 'formSlider' in $.modules) {
        return;
    }

    var FormSlider = function () {
        var self = this;

        self.body = '';
        self.window = '';
        self.container = $('.formSlider');

        /**
         * Setup formSliders in loop (setup as many sliders as there are on page)
         */
        self.setup = function() {
            self.container.each(function() {
                var target = $(this),
                    slider = target.find('.formSlider__slider'),
                    input = target.find('.formSlider__input'),
                    data = self.setupData(target);

                self.setupInput(target, slider, input, data);

                self.setupSlider(target, slider, input, data);

                self.initEvent(target);
            });
        };

        /**
         * Get data from html markup
         * @param {Object} container
         * @returns {Object}
         */
        self.setupData = function(container) {
            return {
                min: parseInt(container.attr('data-min')),
                max: parseInt(container.attr('data-max')),
                current: parseInt(container.attr('data-current'))
            };
        };

        /**
         * Setup input, insert current slider value, add events on input and on blur
         */
        self.setupInput = function(container, slider, input, data) {
            input.val(self.encode(data.current));

            input.on('input', function() {
                var value = self.decode($(this).val()) || 0;

                value = (value < data.min) ? data.min : value;
                value = (value > data.max) ? data.max : value;

                slider.slider('value', value);
            });

            input.on('blur', function() {
                var target = $(this),
                    value = self.decode(target.val());

                value = (value < data.min) ? data.min : value;
                value = (value > data.max) ? data.max : value;

                value = self.encode(value);

                target.val(value);
            });

            input.on('keypress', function(event) {
                var target = $(this);

                if (event.keyCode === 10 || event.keyCode === 13) {

                    event.preventDefault();

                    target.blur();

                }
            });
        };

        /**
         * Update input when slider value changes (slide event)
         */
        self.updateInput = function(event, ui, input) {
            if (event.type === 'slide') {

                input.val(self.encode(ui.value));

            }
        };

        /**
         * Trigger container event with slider value converted to percent for graphics module
         * @param {Object} container
         * @param {Number} value
         */
        self.updateEvent = function(container, value) {
            container.trigger({
                type: 'state',
                value: value
            });
        };

        /**
         * Trigger container event and add class to container after init
         * @param {Object} container
         */
        self.initEvent = function(container) {
            container.addClass('js_inited');

            container.trigger({
                type: 'inited'
            });
        };

        /**
         * Init slider
         */
        self.setupSlider = function(container, slider, input, data) {
            slider.slider({
                value: data.current,
                min: data.min,
                max: data.max,
                step: 1000,
                orientation: 'horizontal',
                range: 'min',
                slide: function( event, ui ) {
                    self.updateInput(event, ui, input);
                    self.updateEvent(container, ui.value);
                },
                change: function(event, ui) {
                    self.updateInput(event, ui, input);
                    self.updateEvent(container, ui.value);
                }
            });
        };

        /**
         * Encode number to string
         *
         * @param {Number} value
         * @returns {String}
         */
        self.encode = function(value) {
            return value.toString().replace(/(\d{1,3})(?=(?:\d{3})+$)/g, '$1 ');
        };

        /**
         * Decode string to number
         *
         * @param {String} value
         * @returns {Number}
         */
        self.decode = function(value) {
            return parseInt(value.replace(/\s/g, ""));
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.setup();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.formSlider = new FormSlider();
})();
