(function () {
    if ('modules' in $ && 'newsFeed' in $.modules) {
        return;
    }

    var NewsFeed = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.newsFeed');
        self.items = $('.newsFeed__item');
        self.link = $('.newsFeed__trigger');
        self.hidden = '';

        self.setupListner = function() {
            self.link.on('click', self.router);
        };

        self.setup = function() {
            var total = self.items.length,
                visible = parseInt(self.container.attr('data-visible-count')),
                hidden = (total - visible) * (-1);

            self.items.slice(hidden).wrapAll("<div class='newsFeed__hidden' />");

            self.hidden = $('.newsFeed__hidden');
        };

        self.router = function(event) {
            event.preventDefault();

            if (!self.container.hasClass('active')) {

                self.open();

            } else {

                self.close();

            }
        };

        self.open = function() {
            self.container.addClass('active');
            self.hidden.slideDown(200);
            self.link.find('span').html(self.link.attr('data-text-open'));
        };

        self.close = function() {
            self.container.removeClass('active');
            self.hidden.slideUp(200);
            self.link.find('span').html(self.link.attr('data-text-default'));
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.setup();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.newsFeed = new NewsFeed();
})();
