(function () {
    if ('modules' in $ && 'compareData' in $.modules) {
        return;
    }

    var CompareData = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.compareData');
        self.list = $('.compareData__data__list');
        self.headings = $('.compareData__heading__item');
        self.cols = $('.compareData__data__item');
        self.arrow = $('.compareData__arrow');
        self.removeButton = $('.compareData__data__remove');

        self.setupListner = function() {
            self.arrow.on('click', self.slide);
            self.removeButton.on('click', self.remove);
        };

        self.initSlider = function() {
            self.list.lightSlider({
                item: 4,
                slideMove: 1,
                slideMargin: 0,
                controls: false,
                pager: true,
                adaptiveHeight: true,
                responsive : [
                    {
                        breakpoint: 1024,
                        settings: {
                            item: 1
                        }
                    }
                ],
                onSliderLoad: function () {
                    self.sliderArrows();
                },
                onAfterSlide: function () {
                    self.sliderArrows();
                }
            });
        };

        self.slide = function(event) {
            event.preventDefault();

            var target = $(this);

            if (target.hasClass('compareData__prev')) {

                self.list.goToPrevSlide();

            } else {

                self.list.goToNextSlide();

            }
        };

        self.sliderArrows = function () {
            var items = self.container.find('.lSPager li'),
                total = items.length,
                active = self.container.find('.lSPager li.active').index() + 1;

            if (active === 0) {

                self.container.addClass('first');
                self.container.addClass('last');

            } else if (active === 1) {

                self.container.removeClass('last');
                self.container.addClass('first');

            } else if (active === total) {

                self.container.removeClass('first');
                self.container.addClass('last');

            } else {

                if (self.container.hasClass('first') || self.container.hasClass('last')) {

                    self.container.removeClass('first last');

                }

            }
        };

        self.remove = function(event) {
            event.preventDefault();

            var target = $(this),
                container = target.closest('.compareData__data__item'),
                prev = container.prev().index() + 1,
                isLast = container.is(':last-child');

            container.remove();
            self.list.refresh();
            self.sliderArrows();

            if (self.container.css('cursor') !== 'pointer') {

                if (prev <= 4) {

                    self.rebuild();

                }

            } else {

                if (prev <= 1) {

                    self.rebuild();

                }

            }
        };

        self.rebuild = function() {
            self.list.destroy();
            self.list = $('.compareData__data__list');
            self.initSlider();
        };

        self.initHeight = function() {
            self.headings.each(function(index) {
                var heading = $(this),
                    items = self.cols.find('.compareData__data__list__content__item:nth-child('+ (index + 1) +')'),
                    row = heading.add(items);

                row.matchHeight({
                    byRow: false
                });
            });
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.initHeight();
            self.initSlider();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.compareData = new CompareData();
})();
