(function () {
    if ('modules' in $ && 'reviews' in $.modules) {
        return;
    }

    var Reviews = function () {
        var self = this;

        self.body = '';
        self.container = $('.reviews');
        self.form = $('.reviews__form');
        self.btn = $('.reviews__btn');

        self.setupListner = function() {
            self.btn.on('click', self.formOpen);
        };

        self.formOpen = function(event) {
            event.preventDefault();

            if (!self.btn.hasClass('active')) {

                self.open()

            } else {

                self.close();

            }
        };

        self.open = function() {
            self.btn.addClass('active focus');
            self.form.slideDown(200);

            self.btn.one('mouseleave', function() {
                var target = $(this);

                target.removeClass('focus');
            });
        };

        self.close = function() {
            self.btn.removeClass('active focus');
            self.form.slideUp(200);
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.reviews = new Reviews();
})();
