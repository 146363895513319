(function () {
    if ('modules' in $ && 'subNavigation' in $.modules) {
        return;
    }

    var SubNavigation = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.subNavigation__wrapper');
        self.menuItem = $('.navigationDesktop__item.active ul');
        self.main = $('.main');

        self.setupListner = function() {
        };

        self.cloneMenu = function() {
            self.menuItem.clone().appendTo(self.container);
        };

        self.addedClass = function() {
            if ($("section").is(".subNavigation")) {
                self.main.addClass('mainSubNavigation');
            }
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.cloneMenu();
            self.addedClass();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.subNavigation = new SubNavigation();
})();
