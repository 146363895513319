(function () {
    if ('modules' in $ && 'formTextarea' in $.modules) {
        return;
    }

    var FormTextarea = function () {
        var self = this;

        self.body = '';

        self.setupListner = function() {
            self.body.on('click focus blur input change', '.formTextarea.formTextarea_disabled', self.disabled);
        };

        self.disabled = function(event) {
            event.preventDefault();
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.formTextarea = new FormTextarea();
})();
