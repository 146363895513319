(function () {
    if ('modules' in $ && 'header' in $.modules) {
        return;
    }

    var Header = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.header');
        self.position = '';
        self.scroll = 0;
        self.hidden = false;
        self.background = false;
        self.start = 75;
        self.state = '';
        self.windowWidth = '';

        self.setupListner = function() {
            self.window.resize(function() {

                if (self.window.width() !== self.windowWidth) {

                    self.windowWidth = self.window.width();

                    self.stateCheck();

                    self.updatePosition();

                    if (self.state === 'desktop') {
                        // __this.resetMobile();
                    } else {
                        self.resetDesktop();
                    }
                }

            });

            self.window.scroll(function () {

                if (self.state === 'desktop') {
                    self.detectScrollDesktop();
                } else {
                    self.detectScrollMobile();
                }

            });
        };

        self.detectScrollDesktop = function() {
            self.scroll = self.window.scrollTop();

            if (self.scroll > self.position) {

                if (!self.hidden && self.position >= self.start) {
                    self.container.addClass('hidden');
                    self.hidden = true;
                }

            } else {

                if (self.hidden) {
                    self.container.removeClass('hidden');
                    self.hidden = false;
                }

            }

            if (self.scroll > self.start / 2) {

                if (!self.background) {
                    self.container.addClass('background');
                    self.background = true;
                }

            } else {

                if (self.background) {
                    self.container.removeClass('background');
                    self.background = false;
                }

            }

            self.updateValue();
        };

        self.detectScrollMobile = function() {
            self.scroll = self.window.scrollTop();

            if (self.scroll > self.start) {

                if (!self.background) {
                    self.container.addClass('background');
                    self.background = true;
                }

            } else {

                if (self.background) {
                    self.container.removeClass('background');
                    self.background = false;
                }

            }
        };

        self.resetDesktop = function() {
            self.container.removeClass('hidden');
        };

        self.resetMobile = function() {
            self.container.removeClass('background');
            self.hidden = false;
        };

        self.updateValue = function() {
            self.position = self.scroll;
        };

        self.updatePosition = function() {
            self.position = self.window.scrollTop();
        };

        self.stateCheck = function() {
            if (self.container.css('cursor') === 'pointer') {
                self.state = 'mobile';
            } else {
                self.state = 'desktop';
            }
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
            self.position = self.window.scrollTop();
            self.windowWidth = self.window.width();
        };

        self.init = function() {
            self.importDefaults();
            self.stateCheck();

            if (self.state === 'desktop') {
                self.resetMobile();
            } else {
                self.resetDesktop();
            }

            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.header = new Header();
})();
