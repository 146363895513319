(function () {
    if ('modules' in $ && 'navigationMobile' in $.modules) {
        return;
    }

    var NavigationMobile = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.navigationMobile');

        self.setupListner = function() {
            self.body.on('click', '.navigationMobile__item.dropdown .navigationMobile__link', self.navClick);
        };

        self.navClick = function(event) {
            event.preventDefault();

            var item = $(this).closest('.navigationMobile__item');

            if (item.hasClass('active')) {
                item.removeClass('active');
            } else {
                item.addClass('active');
            }
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.navigationMobile = new NavigationMobile();
})();

