(function () {
    if ('modules' in $ && 'burger' in $.modules) {
        return;
    }

    var Burger = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.bodyPadding = 0;
        self.page = $('.main, .footer');
        self.burger = $('.burger');
        self.mobileNavigation = $('.navigationMobile');
        self.container = $('.navigationMobile__content');
        self.navigation = $('.navigationDesktop__list');
        self.headerPrivate = $('.headerPrivate__box');
        self.headerComparison = $('.headerComparison');
        self.search = $('.navigationDesktop__search');
        self.positionTop = 0;
        self.active = false;

        self.setupListner = function() {

            self.burger.click(function(event) {
                event.preventDefault();

                if (!$(this).hasClass('active')) {
                    self.open();
                } else {
                    self.close();
                }
            });

            $(window).on('resize orientationchange', function () {
                if (self.burger.css('display') === 'none' && self.active) {
                    self.close();
                }
            });
        };

        self.open = function() {

            self.active = true;

            self.positionTop = self.body.scrollTop();

            self.bodyPadding = self.body.css('padding-bottom');

            self.burger.addClass('active');

            self.body.addClass('mobileNavigationShow');

            self.mobileNavigation.fadeIn(200, function() {
                self.page.wrapAll('<div class="pageWrapper">');

                self.body.css({
                    'padding-bottom': 0
                })
            });
        };

        self.close = function() {
            self.active = false;

            self.page.unwrap();

            self.body.css({
                'padding-bottom': self.bodyPadding
            })
            .removeClass('mobileNavigationShow');

            self.body.scrollTop(self.positionTop);

            self.burger.removeClass('active');

            self.mobileNavigation.fadeOut(200);
        };

        self.setup = function() {
            self.headerComparison.clone().appendTo(self.container);
            self.navigation.clone().appendTo(self.container);
            self.search.clone().appendTo(self.container);
            self.headerPrivate.clone().appendTo(self.container);

            var list = $('.navigationDesktop__list', self.container),
                item = $('.navigationDesktop__item', self.container),
                link = $('.navigationDesktop__link', self.container);
                dropActive = $('.navigationDesktop__active', self.container);
                comparison = $('.headerComparison', self.container);
                private = $('.headerPrivate__box', self.container);
                search = $('.navigationDesktop__search', self.container);

            list.removeClass('navigationDesktop__list').addClass('navigationMobile__list');
            item.removeClass('navigationDesktop__item').addClass('navigationMobile__item');
            link.removeClass('navigationDesktop__link').addClass('navigationMobile__link');
            dropActive.removeClass('navigationDesktop__active').addClass('navigationMobile__active');
            comparison.removeClass('.headerComparison').addClass('mobileComparison');
            private.removeClass('.headerPrivate__box').addClass('mobilePrivate');
            search.removeClass('.navigationDesktop__search').addClass('navigationMobile__search');
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.setup();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.burger = new Burger();
})();
