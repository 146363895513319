(function () {
    if ('modules' in $ && 'calculator' in $.modules) {
        return;
    }

    var Calculator = function () {
        var self = this;

        self.body = '';
        self.window = '';
        self.encode = '';
        self.container = $('.calculator');
        self.items = $('.calculator__col');
        self.amount = $('.calculator__amount__data span');
        self.slider = $('.calculator .formSlider');

        self.setupListner = function() {
            self.slider.on('state', function(event) {
                var value = event.value;

                self.update(value);
            });
        };

        self.pending = function() {
            if (!self.slider.hasClass('js_inited')) {

                self.slider.on('inited', self.setup);

            } else {

                self.setup();

            }
        };

        self.setup = function() {
            var value = self.slider.find('.formSlider__input').val();

            self.update(value);
        };

        self.update = function(value) {
            self.amount.html(self.encode(value));
        };

        self.initHeight = function() {
            self.items.matchHeight({
                byRow: false
            });
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
            self.encode = $.modules.formSlider.encode;
        };

        self.init = function() {
            self.importDefaults();
            self.initHeight();
            self.pending();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.calculator = new Calculator();
})();
