(function () {
    if ('modules' in $ && 'tableData' in $.modules) {
        return;
    }

    var TableData = function () {
        var self = this;

        self.window = '';
        self.body = '';
        self.container = $('.tableData');

        self.setupListner = function() {
        };

        self.importDefaults = function() {
            self.body = $.modules.main.body;
            self.window = $.modules.main.window;
        };

        self.init = function() {
            self.importDefaults();
            self.setupListner();
        };
    };

    if (!('modules' in $)) {
        $.modules = {};
    }

    $.modules.tableData = new TableData();
})();
